@import 'github-markdown-css/github-markdown.css';

@font-face {
  font-family: "Myriad";
  src: url("/assets/fonts/MyriadPro/MP-Bold.woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Myriad";
  src: url("/assets/fonts/MyriadPro/MP-Semibold.woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Myriad";
  src: url("/assets/fonts/MyriadPro/MP-Regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Myriad";
  src: url("/assets/fonts/MyriadPro/MP-Semibold.woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Aldo";
  src: url("/assets/fonts/Aldo/Aldo.ttf");
  font-weight: 600;
  font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html {
  font-family: 'Myriad';
  scroll-behavior: smooth;
}

body{
  font-size: 16px;
  color: #19130C;
  font-family: 'Myriad';
  scroll-behavior: smooth;
}

::selection {
  background-color: #19130C;
  color: white;
}

.bg-blend-multiply{
  background-blend-mode: multiply;
}

.mix-blend-multiply{
  mix-blend-mode: multiply;
}

.galery-grid{
  grid-template-rows: max-content 1fr max-content;
}


.collapsable {
  transition:height 0.3s ease-in-out;
  height:auto;
}

input[type=radio]:checked + div{

  @apply bg-brown-light border-brown-light;

  div{
    display: flex;
  }
}

input[type=checkbox]:checked + div{

  @apply bg-brown-light border-brown-light;
}


.hide-arrows{

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}

.backdrop-blur-sm {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}


/* width */
.scrollbar::-webkit-scrollbar {
  margin: 10px 0px;
  width: 4px;
  border-radius: 9999px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #C4C4C4;
  border-radius: 9999px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #4A6019;
  border-radius: 9999px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #425616;
}

.custom-border {
  border-radius: 35px / 40px;
}

.markdown-body {

  font-family: Minion;

  h1, h2, h3, h4, h5, h6 {
    border-bottom: none;
  }

  img {
    margin-bottom: 24px;
  }
}

.dotted-deco{
  text-decoration-style: dotted;
}

.font-outline {
    color: transparent;
    transition-duration: 200ms;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.font-outline-brown {
  color: transparent;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #897A69;
  // text-shadow: -1px 1px 0 #897A69,
	// 			  1px 1px 0 #897A69,
	// 			  1px -1px 0 #897A69,
	// 			  -1px -1px 0 #897A69;
}

.hover-outline:hover {
  -webkit-text-fill-color: white;
}

@media screen and (min-width: 768px) {
  .font-outline-brown {
    -webkit-text-stroke-width: 1.5px;
  }

  .font-outline {
    -webkit-text-stroke-width: 1.5px;
  }
}

.pb-full {
  padding-bottom: 100%;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
}

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(137, 122, 105, 0.48) !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid  !important;
}

.MuiInputBase-input::placeholder {
  color: rgba(137, 122, 105, 0.78)!important;
  font-weight: 200 !important;
}

.MuiInputBase-input {
  padding:  12px 0 !important;
}

.MuiTypography-subtitle2 {
  font-size: 14px !important;
  font-weight: lighter !important;
  color: #666666;
}

.MuiSlider-root {
  padding: 24px 0 12px 0 !important;
}

.MuiSlider-track, .MuiSlider-rail {
  background-color: rgba(137, 122, 105, 0.48) !important;
}

.MuiCheckbox-root {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  color: rgba(137, 122, 105, 0.28) !important;
}

.Mui-checked {
  color:rgba(137, 122, 105, 1) !important
}

.MuiTypography-root {
  font-family: "Myriad" !important;
  font-size: 14px;
  font-weight: normal;
  color :#666666;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  font-weight: normal !important;
}

.load {
  animation-iteration-count: infinite;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-name: loading-animation;
}

@keyframes loading-animation {
  0% {
      background-color: rgba(0, 0, 0, 0);
  }
  50% {
      background-color: rgba(0, 0, 0, 0.06);
  }
  100% {
      background-color: rgba(0, 0, 0, 0);
  }
}

#date {
  cursor: pointer;
}

.Mui-focused {
  background-color: transparent !important;

  * {
    background-color: transparent !important;
  }
}

.MuiButtonBase-root {
  background-color: transparent !important;

  * {
    background-color: transparent !important;
  }
}

// iframe {
//   display: none !important
// }

.lock-scroll {
  overflow-y: hidden !important;
  height: 100vh !important;
}